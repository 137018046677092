import { GetAcceptanceForUserOut, GetDocumentContentOut } from './interfaces';

export class ArgoHadesDevClient {
    getAcceptanceForUser = async (userName: string): Promise<GetAcceptanceForUserOut> => {
        return Promise.resolve({
            hasAccepted: true,
            acceptanceDate: new Date(),
            acceptingUser: userName,
            shouldPromptForAcceptance: false
        });
    };

    setAcceptanceForUser = async (userName: string) => {
        // no-op
        return Promise.resolve();
    };

    getSiteTerms = async (): Promise<GetDocumentContentOut> => {
        const loremIpsum = `<div><p>This is a mock Legal Agreement.</p> <p>Lorem ipsum dolor sit amet, \
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna \
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip \
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse \
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, \
            sunt in culpa qui officia deserunt mollit anim id est laborum.</p></div> \
            <div><h2>Some more lorem ipsum</h2> <p>Lorem ipsum dolor sit amet, \
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna \
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip \
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse \
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, \
            sunt in culpa qui officia deserunt mollit anim id est laborum.</p></div> \
            <div><h2>Some more lorem ipsum</h2> <p>Lorem ipsum dolor sit amet, \
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna \
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip \
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse \
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, \
            sunt in culpa qui officia deserunt mollit anim id est laborum.</p></div> \
            <div><h2>Some more lorem ipsum</h2> <p>Lorem ipsum dolor sit amet, \
            consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna \
            aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip \
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse \
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, \
            sunt in culpa qui officia deserunt mollit anim id est laborum.</p></div>`;

        return Promise.resolve({
            content: loremIpsum
        });
    };
}