import KatalMetricsPublisher from '@katal/metrics/lib/KatalMetricsPublisher';
import { CredentialStorage } from "../../../utils/CredentialStorage";

let internalExternalMetricSent = false;

export const setInternalExternalMetricSent = (value: boolean) => {
  internalExternalMetricSent = value;
};

const getInternalExternalMetricSent = () => {
  return internalExternalMetricSent;
};

export function sendInternalExternalMetric(metricsPublisher: KatalMetricsPublisher, credentialStorage: CredentialStorage) {
  if (!getInternalExternalMetricSent()) {
    if (credentialStorage.isExternalUser()) {
      metricsPublisher.newChildActionPublisherForMethod('InternalExternalUser').publishCounterMonitor('External', 1);
    } else {
      metricsPublisher.newChildActionPublisherForMethod('InternalExternalUser').publishCounterMonitor('Internal', 1);
    }
    setInternalExternalMetricSent(true);
  }
}
