import * as Sentry from "@sentry/react";
import { getSentryDefaultConfig, getSentryCustomizedConfig } from './SentryConfig';
import { ClientOptions } from '@sentry/types';

export function initializeSentry(customSentryConfig?: Partial<ClientOptions>){
    if (!customSentryConfig) {
        Sentry.init(getSentryDefaultConfig())
    } else {
        Sentry.init(getSentryCustomizedConfig(customSentryConfig))
    }
}
