import { createContext, useContext } from 'react';
import { ArgoContext } from './index';
import { FrameworkStage, FrontEndFeatureSet } from './ArgoEnums';
import initialMetricsPublisher, { ArgoMetricsConfig } from './utils/metrics/metrics';
import katalLogger from './utils/logger/logger';
import { Level } from "@katal/logger";

export const metricsErrorhandler = (err: Error) => {
  return err;
};

const initialMetricsConfig: ArgoMetricsConfig = {
  appName: 'Argo',
  region: 'us-west-2',
  frameworkStage: 'test',
  cell: 'cell-0',
  browserName: 'test-browser',
  deviceType: 'test-device',
  deviceOS: 'test-os',
  locale: 'test-locale',
  applicationVisitId: 'test-applicationVisitId',
  argoSessionId: 'test-argoSessionId'
};

const defaultContext: ArgoContext = {
  user: {
    username: 'default',
    signOut: () => {
      throw new Error(
        'Default context: Place an ArgoAppContextProvider ' +
        'near the root of your component hierarchy'
      );
    },
  },
  frameworkStage: FrameworkStage.prod,
  frontEndFeatureSet: FrontEndFeatureSet.Release,
  fetchWithWebIdentity: (input, init?) => {
    return Promise.reject(
      'Default context: Place an ArgoAppContextProvider ' +
      'near the root of your component hierarchy'
    );
  },
  fetchWithAwsAuth: (input, init?) => {
    return Promise.reject(
        'Default context: Place an ArgoAppContextProvider ' +
        'near the root of your component hierarchy'
    );
  },
  custom_groups: [],
  initialMetricsPublisher: initialMetricsPublisher(initialMetricsConfig),
  logger: katalLogger(
    Level.DEBUG,
    {
      appName: 'test'
    },
    true
  ),
  region: 'us-west-2',
};

const ArgoAppContext = createContext(defaultContext);
export const useArgoContext = () => useContext(ArgoAppContext);
export const ArgoAppContextConsumer = ArgoAppContext.Consumer;
export const ArgoAppContextProvider = ArgoAppContext.Provider;
