import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client/core';
import { credentialStorage } from '../CredentialStorage';
import { GetAcceptanceForUserOut, GetDocumentContentOut } from './interfaces';

export class ArgoHadesClient {
    _fetchWithWebIdentity = (input: RequestInfo, init?: RequestInit) => {
        const token = credentialStorage.getJwtToken();
        if (!init) {
            init = {};
        }
        const headers = new Headers(init.headers);
        headers.append('Authorization', token);
        init.headers = headers
        return fetch(input, init).then((response) => {
            return response;
        });
    };

    _getInstance = () => {
        return new ApolloClient({
            cache: new InMemoryCache({}),
            link: new HttpLink({
                uri: '/graph',
                fetch: this._fetchWithWebIdentity
            }),
            queryDeduplication: false
        });
    }

    getAcceptanceForUser = async (userName: string): Promise<GetAcceptanceForUserOut> => {
        return await this._getInstance().query({
            query: gql`query GetAcceptanceForUser($userId: String!) {
              getAcceptanceForUser(userId: $userId) {
                  hasAccepted
                  acceptanceDate
                  acceptingUser
                  shouldPromptForAcceptance
              }
          }`,
            variables: {
                userId: userName
            }
        }).then((result) => {
            return result.data.getAcceptanceForUser as GetAcceptanceForUserOut;
        });
    };

    setAcceptanceForUser = async (userName: string) => {
        return await this._getInstance().mutate({
            mutation: gql`mutation SetAcceptanceForUser($userId: String!) {
              setAcceptanceForUser(userId: $userId) {
                 result
              }
          }`,
            variables: {
                userId: userName
            }
        });
    };

    getSiteTerms = async (): Promise<GetDocumentContentOut> => {
        return await this._getInstance().query({
            query: gql`query GetDocumentContent {
              getDocumentContent {
                  content
              }
          }`,
        }).then((result) => {
            return result.data.getDocumentContent as GetDocumentContentOut;
        })
    };
}