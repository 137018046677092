import { useEffect } from 'react';
import * as KatalMetrics from '@katal/metrics';
import { createUserUsagePublisher } from './createUserUsagePublisher';
import { useLocation } from 'react-router-dom'; // useLocation requires a parent Router component, which must be defined in the Argo App

const HEARTBEAT_INTERVAL_IN_MS = 30_000;

export const useUserUsageMetrics = (metricPublisher: KatalMetrics.Publisher, isAuthInitialized: boolean, appName: string) => {
  const location = useLocation();

  let applicationEndMetricPublished = false;
  const publishApplicationEndMetric = (usageMetricPublisher: KatalMetrics.Publisher) => {
    if (applicationEndMetricPublished) {
      return;
    }

    usageMetricPublisher.newChildActionPublisher().publishString('application-end', appName);
    applicationEndMetricPublished = true;
  }

  /**
   * Firing a metric on unload or on beforeunload does not always make it to the backend.
   * Creating a heartbeat for page "visibility" is the next-best attempt at measuring when an application is not being
   * used anymore. If we don't see an 'application-end' metric, then the last known 'application-heartbeat' is the closest guess
   * as to when the user stopped using the application.
   *
   * This also grants a measure of when a browser tab with the application is open (this will not fire a metric when the browser tab is hidden).
   */
  const setupPageVisibilityHeartbeat = (usageMetricPublisher: KatalMetrics.Publisher) => {
    setInterval(() => {
      if (document.visibilityState === 'visible') {
        usageMetricPublisher.newChildActionPublisher().publishString('application-heartbeat', appName);
      }
    }, HEARTBEAT_INTERVAL_IN_MS);

    // Ref: https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event#usage_notes
    // visibilitychange is the last reliably observable event for a user navigating away from a page.
    // It is possible that a user may come back to the page after this event (within the same session).
    addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        usageMetricPublisher.newChildActionPublisher().publishString('application-hidden', appName);
      } else {
        usageMetricPublisher.newChildActionPublisher().publishString('application-visible', appName);
      }
    });
  }

  // Triggered when `isAuthInitialized` value changes.
  useEffect(() => {
    // Instead of trying to fire metrics immediately when the app is rendered,
    // wait until the user is logged in so that we can tie metric actions to a user_unique_id.
    if (isAuthInitialized) {
      const userUsagePublisher = createUserUsagePublisher(metricPublisher, isAuthInitialized);

      userUsagePublisher.newChildActionPublisher().publishString('application-start', appName);

      // Ref: https://calendar.perfplanet.com/2020/beaconing-in-practice/#beaconing-reliability-avoiding-abandons
      // Due to browser differences, this may only catch ~70% of unload events.
      addEventListener('beforeunload', () => publishApplicationEndMetric(userUsagePublisher), { capture: true });
      addEventListener('unload', () => publishApplicationEndMetric(userUsagePublisher), { capture: true });

      // Add a session heartbeat to catch the other 30%.
      setupPageVisibilityHeartbeat(userUsagePublisher);
    }
  }, [isAuthInitialized]);

  // Triggered when `isAuthInitialized` || `location` values change.
  useEffect(() => {
    if (isAuthInitialized) {
      const userUsagePublisher = createUserUsagePublisher(metricPublisher, isAuthInitialized);

      userUsagePublisher.newChildActionPublisher().publishString('route-change', location.pathname);
    }
  }, [isAuthInitialized, location]);
}