import { UserManager, WebStorageStateStore } from "oidc-client";
import {logInfo, logWarn} from "./utils/logger";
import { AuthSession, AuthConfig, AuthUser, SessionServiceCredential, AuthResponseType } from "./type";
import OIDC from "./OIDC";


export default class AuthManager {

    private UserManager : UserManager | undefined;
    private userKey : string | undefined;
    private authConfig : AuthConfig | undefined;
    private authFlowType : string | undefined;
    private sessSvcKey = "SessSvcResponse";

    public getUserManager() : UserManager | undefined {
        return this.UserManager;
    }

    public setUserManager(userManager : UserManager | undefined) : void {
        this.UserManager = userManager;
    }

    public configure (config : AuthConfig) : void {
        this.authConfig = config;
        this.authFlowType = config.responseType;

        //userKey used to save token inside local storage. 
        this.userKey = 'oidc.user:' + config.identityProviderUrl + ':' + config.clientId;

        this.UserManager = new UserManager({
            response_mode: 'query',
            authority: config.identityProviderUrl,
            client_id: config.clientId,
            redirect_uri: config.redirectSignIn,
            automaticSilentRenew: false,
            validateSubOnSilentRenew: false,
            loadUserInfo: false,
            silent_redirect_uri: config.redirectSignIn,
            post_logout_redirect_uri: config.redirectSignOut,
            filterProtocolClaims: true,
            response_type: config.responseType,
            scope: config.scope,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            metadata: {
                issuer: config.identityProviderUrl,
                jwks_uri: config.jwkEndpoint,
                authorization_endpoint: config.authorizationEndpoint,
                token_endpoint: config.tokenEndpoint,
                userinfo_endpoint: config.userInfoEndpoint,
                end_session_endpoint: config.endSessionEndpoint,
            }
        });
    }

    // get the signed in user. return type: AuthUser
    public async getUser() : Promise<AuthUser | null> {
        if (!this.authConfig) {
            return null;
        }
        const userkey = this.userKey;
        if (userkey && window.localStorage.getItem(userkey)) {
            const authUserString = window.localStorage.getItem(userkey);
            if (authUserString) {
                const user = JSON.parse(authUserString);
                const expireTime = user.expires_at;
                const currentTime = new Date().getTime()/1000;
                if (currentTime >= expireTime) {
                    logInfo('AuthManager.token has expired', 'current time ' + currentTime.toString() + ' expire time '+ expireTime.toString());
                    window.localStorage.removeItem(userkey);
                    window.localStorage.removeItem(this.sessSvcKey);
                    window.location.assign(this.authConfig.redirectSignIn);
                    return null;
                }
                return user;
            }
        }
        return null;
      }

    // generate AuthSession to return to Portal
    public async getSession() : Promise<AuthSession | null> {
        const user = await this.getUser();
        if (user && window.localStorage.getItem(this.sessSvcKey)) {
            const session : AuthSession = {
                idToken: user.id_token,
                credentials: this.parseSessionSvcResponse(),
            };
            return session;
        } else {
            return null;
        }
    }

    private parseSessionSvcResponse() : SessionServiceCredential | null {
        const sessSvcResponseString = localStorage.getItem(this.sessSvcKey);
        return sessSvcResponseString ? JSON.parse(sessSvcResponseString) : null;
    }

    // sign in and return session containing the id_token. return type: AuthSession
    public async signinSession() : Promise<AuthSession | null> {
        // check if already signed in
        try {
            let session;
            if (await this.getUser() && (session = await this.getSession())) {
                return session;
            }
        } catch (e) {
            logInfo('AuthManager', 'Check init user fail, user is not logged in yet');
        }

        try {
            if (this.authFlowType == AuthResponseType.Code) {
                await this.signin();
            } else if (this.authFlowType == AuthResponseType.IdToken) {
                await this.signinImplicit();
            } else {
                logWarn("AuthManager", "Authflow type not supported");
                throw (new Error("Unsupported auth flow type"));
            }
        } catch (e) {
            logWarn('AuthManager.signinSessionError', e);
            throw(e);
        }

        return await this.getSession();
    }

    private setUserToStorage(token: string, time: number) {
        const explictUser : AuthUser = {
            id_token: token,
            access_token: "",
            refresh_token: "",
            scope: "",
            token_type: "token",
            profile: null,
            expires_at: time,
        };
        const userkey = this.userKey;

        if (userkey) {
            window.localStorage.setItem(userkey, JSON.stringify(explictUser));
        }
    }

    private getTokenExpireTime() : number {
        // check expiretime number | undefined to pass typescript check. The default actually won't be reached.
        // Under current structure, this getTokenExpireTime() will only be called after checking configured or not
        // therefore always been called with a valid expireTime.
        // TODO: if future update changed the calling strategy, review the default expire time
        const defaultExpireTime = 600; // Default set to same as Portal refresh time
        if (!this.authConfig || !this.authConfig.tokenExpirationTime) {
            return new Date().getTime()/1000 + defaultExpireTime;
        } else {
            return new Date().getTime()/1000 + this.authConfig.tokenExpirationTime;
        }
    }

    private signinImplicitCheckToken() : boolean {
        const currentUrl = new URL(window.location.href);
        const implicitToken = currentUrl.searchParams.get('id_token');
        if (implicitToken) {
            this.setUserToStorage(implicitToken, this.getTokenExpireTime());
            window.history.replaceState(null, '', window.location.pathname);
            return true;
        } else {
            return false;
        }
    }

    private getFullPath(): string {
        return window.location.href.replace(window.location.origin, "")
    }

    // Implicit sign in flow, used by token flow
    private async signinImplicit() : Promise<void> {
        if (!this.UserManager) {
            return;
        }

        if (!this.signinImplicitCheckToken()) {
            try {
                await this.UserManager.signinRedirect({});
            } catch (e) {
                logWarn('AuthManager.ImplicitSignin fail calling UserManager.signinRedirect', e);
                throw e;
            }
        }

        try {
            await this.callSessionService();
        } catch (e) {
            logWarn('AuthManager.ImplicitSignin call session service fail', e);
            throw (e);
        }
    }

    // Code sign in flow, used by Cognito
    private async signin() : Promise<void> {
        if (!this.UserManager || !this.authConfig) {
            return;
        }

        const currentUrl = new URL(window.location.href);
        const code = currentUrl.searchParams.get('code');

        if (!code) {
            // sign in redirect: sign in and get the code
            try {
                await this.UserManager.signinRedirect({state: this.getFullPath()});
            } catch (e) {
                logWarn('AuthManager.CodeSignin fail calling UserManager signinRedirect', e);
                throw e;
            }

        } else {
            let path = '';
            // sign in redirect callback: use the code to get the token
            try {
                const user = await this.UserManager.signinRedirectCallback();
                if (user.state) {
                    //we want to avoid double slashes in url
                    path = this.authConfig.redirectSignIn.endsWith('/') ?  user.state.replace(/^\//, '') : user.state;
                }
            } catch (e) {
                logWarn('AuthManager_ts.CodeSigninCallback fail', e);
                // Re initialize the login if the SigninCall back fail. Avoid getting stuck at random 'code?=' page.
                window.location.assign(this.authConfig.redirectSignIn);
                throw e;
            }

            // call session service first, then redirect to application to avoid racing condition
            try {
                await this.callSessionService();
            } catch (e) {
                logWarn('AuthManager.CodeSignin call session service fail', e);
                throw (e);
            }

            if (!path){
                // redirect to the app path if has state saved before.
                // For example, if user directly visit portal.com/Townsend.  
                // After redrect from identity provider, user should be redirected back to Townsend.
                window.history.replaceState(null, '', window.location.pathname);
            } else {
                window.location.replace(this.authConfig.redirectSignIn + path);
            }
        }
    }

    // refresh token, return session containing the id_token. return type: AuthSession
    public async refreshSession() : Promise<AuthSession | null>{
        if (!this.UserManager) {
            return null;
        }
        if (this.authFlowType == AuthResponseType.Code) {
            await this.silentSignin();
        } else if (this.authFlowType == AuthResponseType.IdToken) {
            await this.silentSigninImplicit();
        } else {
            logWarn("AuthManager", "Authflow type not supported");
            throw (new Error("Unsupported auth flow type"));
        }

        // call session service for both code flow and token flow to support graphV2
        try {
            await this.callSessionService();
        } catch (e) {
            logWarn('AuthManager.refresh call session service fail', e);
            throw (e);
        }
        return await this.getSession();
    }

    // silent sign in to refresh endpoint
    private async silentSignin() : Promise<void>{
        if (!this.UserManager) {
            return;
        }
        try {
            await this.UserManager.signinSilent();
            return;
        } catch (e) {
            logWarn('AuthManager.silent sign in fail calling UserManager signinSilent', e)
            throw (e);
        }
    }

    // Generate nonce: use the same format and function oidc-client is using to generate uuidv4
    // https://github.com/IdentityModel/oidc-client-js/blob/dev/src/random.js
    private generateNonce() : string {
        return (1e7.toString()+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => 
            (parseInt(c) ^ Math.random() * 16 >> parseInt(c) / 4).toString(16)
        );
    }

    private async refreshImplicitTokenRequest() {
        if (!this.authConfig) {
            return;
        }
        const nonce = this.generateNonce();
        const url = this.authConfig.implicitFlowRefreshEndpoint +
            "?redirect_uri=" + this.authConfig.redirectSignIn +
            "&client_id=" + this.authConfig.clientId +
            "&scope=openid&response_type=id_token" +
            "&nonce=" + nonce;

        await fetch(url ,{
            mode:'cors',
            method: 'GET',
            credentials: 'include',
        }).then(response =>  response.text().then(text => ({ok: response.ok, text: text})))
        .then(responseData => {
            if (responseData.ok) {
                this.setUserToStorage(responseData.text, this.getTokenExpireTime());
            }
        }).catch(e => {
            logWarn("error calling session service", e);
            throw e;
        });
    }

    // Implicit flow does not have refresh endpoint, use HttpRequest sent to midway
    private async silentSigninImplicit() : Promise<void> {
        try {
            await this.refreshImplicitTokenRequest();
        } catch (e) {
            logWarn('AuthManager.refresh implicit token fail', e);
            throw (e);
        }
    }

    // Call Session Service API Gateway to obtain SessionServiceCredential
    public async callSessionService() : Promise<SessionServiceCredential | null>{
        const user = await this.getUser();
        if (!user || !this.authConfig) {
            return null;
        }
        const apiUrl = this.authConfig.sessionServiceEndpoint;
        await fetch(apiUrl,{
            mode:'cors',
            method: 'GET',
            headers:{
                Authorization: user.id_token,
            }
        }).then(function(response : Response) {
            return response.json();
        })
        .then(responseData => {
            const sessSvcResponseString = JSON.stringify(responseData);
            localStorage.setItem(this.sessSvcKey, sessSvcResponseString);
            logInfo("AuthManager.SessionService call got response ", sessSvcResponseString);
        }).catch(e => {
            logWarn("error calling session service", e);
            // Preserve error message in local storage rather than in response to Portal
            // Now users won't be blocked during login. Possible errors will be thrown when calling grpahV2
            localStorage.setItem(this.sessSvcKey, JSON.stringify({"error": e.toString()}));
        });
        return this.parseSessionSvcResponse();
    }

    // sign out
    public async signOut() : Promise<void>{
        if (!this.UserManager || !this.authConfig) {
            return;
        }
        if (this.userKey) {
            window.localStorage.removeItem(this.userKey);
        }
        window.localStorage.removeItem(this.sessSvcKey);
        for (const key in localStorage) {
            if (key.startsWith('oidc')) {
                localStorage.removeItem(key);
            }
        }
        try {
            await this.UserManager.signoutRedirect({
                id_token_hint: localStorage.getItem("id_token"),
                extraQueryParams: {
                    'client_id': this.authConfig.clientId,
                    'redirect_uri': this.authConfig.redirectSignOut,
                    'response_type': this.authFlowType,
                },
            });
        } catch (e) {
            logWarn('AuthManager.signout failuer', e);
            throw (e);
        }
        this.UserManager.clearStaleState();
    }
}

export const Auth = new AuthManager();
OIDC.register(Auth);