import * as React from 'react';
import Box from '@amzn/awsui-components-react-v3/polaris/box';
import ButtonDropdown, { ButtonDropdownProps } from '@amzn/awsui-components-react-v3/polaris/button-dropdown';
import { ArgoContext, useArgoContext } from '../../index';
import strings from './strings';
import './header.scss';

const ACCOUNT_OPTIONS = [
  {
    id: 'account',
    text: 'Account',
    items: [
      {
        id: 'account.permissions',
        disabled: true,
        text: 'My Permissions',
      },
      {
        id: 'account.subscriptions',
        disabled: true,
        text: 'My Subscriptions',
      },
      {
        id: 'account.sign-out',
        disabled: false,
        text: 'Sign Out',
      },
    ],
  },
  {
    id: 'preference',
    text: 'Preferences',
    disabled: true,
    items: [
      {
        id: 'preference.language',
        text: 'Language',
      },
      {
        id: 'preference.time-zone',
        text: 'Time Zone',
      },
      {
        id: 'preference.notification',
        text: 'Notification',
      },
    ],
  },
];

interface Props {
  context: ArgoContext;
}

export function HeaderWithProps(props: Props) {
  const { context } = props;
  const onButtonDropdownClick = (e: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    if (e.detail.id === 'account.sign-out') {
      context.user.signOut();
    }
  };

  return (
    <Box>
      <header className='argo-header' data-cy='argo-header'>
        <ul className='flex-box-align-items-center-and-justify-content-space-between menu-list'>
          <li className='argo-logo'>{strings.title}</li>
          <li>
            <ul className='flex-box'>
              <li>
                <ButtonDropdown
                  items={ACCOUNT_OPTIONS}
                  onItemClick={onButtonDropdownClick}
                >
                  {strings.welcome(context.user.username)}
                </ButtonDropdown>
              </li>
            </ul>
          </li>
        </ul>
      </header>
    </Box>
  );
}

export function HeaderWithContext() {
  return <HeaderWithProps context={useArgoContext()} />;
}
