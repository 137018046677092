import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  FC,
} from 'react';
import { get as _get } from 'lodash';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useArgoContext } from '../../ArgoContext';
import { makePublisher } from './metrics';
import { getArgoConfig } from '../../window';
import * as LoggerUtility from '../../utils/logger/utility';

const argoConfig = getArgoConfig();
const appName = _get(argoConfig, 'argo.applicationName', 'Argo');
const region = _get(argoConfig, 'argo.region', 'us-west-2');
const frameworkStage = _get(argoConfig, 'argo.frameworkStage', 'dev');
const cell = _get(argoConfig, 'argo.cell', 'cell-0');
const metricConfig = {
  appName,
  region,
  frameworkStage,
  cell,
  browserName: LoggerUtility.getUserBrowserName(),
  deviceType: LoggerUtility.getUserDeviceType(),
  deviceOS: LoggerUtility.getUserDeviceOS(),
  locale: LoggerUtility.getUserLocale(),
  applicationVisitId: '',
  argoSessionId: '',
};

const AGGREGATE = 'aggregate';
const ROUTE_LOAD_DURATION = 'routeLoadDuration';

const aggregateMetricPublisher = makePublisher(metricConfig, `any.${frameworkStage}.any.any`);
const cellMetricPublisher = makePublisher(metricConfig, `any.${frameworkStage}.${region}.${cell}`);

const RouteLoadDurationContext = createContext<(routeName: string) => void | never>((_: string) => {
  throw new Error(`Cannot publish routeLoadDuration. Wrap your application's routes in a RouteLoadDurationProvider to enable this functionality.`);
});

interface Props {
  onPublish?: ({ routeName, duration }: { routeName: string, duration: number }) => void;
}

export const RouteLoadDurationProvider: FC<Props> = ({ onPublish, children }) => {
  const { pathname } = useLocation();
  const { initialMetricsPublisher: appMetricPublisher } = useArgoContext();

  const startTime = useRef<number|null>(null);

  useEffect(() => {
    startTime.current = performance.now();
  }, [pathname]);

  const publishRouteLoadDuration = (routeName: string) => {
    if (!startTime.current) return;

    const duration = Math.round(performance.now() - startTime.current);

    const publishers = [
      aggregateMetricPublisher,
      cellMetricPublisher,
      appMetricPublisher,
    ];

    // Publish aggregate metrics
    publishers.forEach(publisher => {
      publisher
        .newChildActionPublisherForMethod(AGGREGATE)
        .publishTimerMonitor(ROUTE_LOAD_DURATION, duration);
    });

    // Publish route-specific metric
    appMetricPublisher
      .newChildActionPublisherForMethod(routeName)
      .publishTimerMonitor(ROUTE_LOAD_DURATION, duration);

    startTime.current = null;

    if (!onPublish) return;

    onPublish({ routeName, duration });
  };

  return (
    <RouteLoadDurationContext.Provider value={publishRouteLoadDuration}>
      {children}
    </RouteLoadDurationContext.Provider>
  );
};

export const useRouteLoadDurationPublisher = (route?: string):() => void => {
  const publishRouteLoadDuration = useContext(RouteLoadDurationContext);
  const path = route ? route : useRouteMatch().path;

  // Wrapping this in setTimeout() defers publishing metrics
  // until the main thread finishes the current render cycle,
  // which means resulting metrics include time-to-paint
  return () => setTimeout(() => publishRouteLoadDuration(path));
};
