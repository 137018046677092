import { logWarn } from './utils/logger';
import { AuthConfig } from './type';

export default class OIDC {
    // follow aws-amplify to set type: any
    // so that OIDC.ts does NOT need to import from UserManager.ts
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static Auth: any = null;

    // register Auth component to OIDC
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    static register(comp : any) : void {
      this.Auth = comp;
    }

    // configure the Auth component
    static configure(config : AuthConfig) : void {
      if (this.Auth) {
        try {
          if (config.responseType == 'code' && !config.tokenEndpoint) {
            throw (new Error("Missing specific information for Cognito endpoint: tokenEndpoint"));
          }
          if (config.responseType == 'id_token' && !config.implicitFlowRefreshEndpoint) {
            throw (new Error("Missing specific information for Midway endpoint: implicitFlowRefreshEndpoint"));
          }
          this.Auth.configure(config);
        } catch (e) {
          logWarn('OIDC.configure', e);
          throw(e);
        }
      }
    }
}
