import KatalLogger, {Level} from '@katal/logger';
import { credentialStorage } from '../CredentialStorage';

export const makeKatalLogger = (
    logThreshold: Level,
    context: object,
    logToConsole: boolean,
    url?: string
) => {
    return new KatalLogger({
        url,
        logThreshold,
        context,
        logToConsole,
        headers: {
            Authorization: credentialStorage.getJwtToken(),
        }
    });
};

const katalLogger = (
    logThreshold: Level,
    context: object,
    logToConsole: boolean,
    url?: string
) => makeKatalLogger(logThreshold, context, logToConsole, url);

export default katalLogger;
