const getRealmName = (region: string): string => {
  const regionArea: string = region.split('-')[0];

  switch (regionArea) {
    case 'us':
      return 'USAmazon';
    case 'eu':
      return 'EUAmazon';
    case 'cn':
      return 'CNAmazon';
    default:
      return 'USAmazon';
  }
};

export default getRealmName;
