import * as KatalMetrics from '@katal/metrics';
import { createUserUsagePublisher } from './createUserUsagePublisher';

/**
 * Publishes a 'page-action' metric to be used in-tandem with userUsageMetrics. A page action is an action on a Single
 * Page Application that does not trigger a route change, but is still meaningful to be monitored (e.g. button clicks, wizard steps)
 * @param metricPublisher The initialMetricsPublisher from ArgoContext
 * @param actionName The name of the action associated with the event.
 */
export const publishPageAction = (metricPublisher: KatalMetrics.Publisher, actionName: string) => {
  const userUsagePublisher = createUserUsagePublisher(metricPublisher, true);

  userUsagePublisher.newChildActionPublisher().publishString('page-action', actionName);
}
