import React from 'react';
import { AppLayout, Box, Button, Header } from '@amzn/awsui-components-react-v3';
import strings from '../strings'
import { generateLegalAgreementText } from '../generateLegalAgreementText';
import '@amzn/awsui-global-styles/polaris.css';

interface LegalAgreementCaptivePortalProps {
  onSubmitContinue: () => void
  siteTermsText: string
}

export const LegalAgreementCaptivePortal = ({ onSubmitContinue, siteTermsText }: LegalAgreementCaptivePortalProps) => {
  const ContentHeader = () => (
    <Header variant='h2'>
      {strings.siteUse}
    </Header>
  );

  const Content = () => (
    <>
      {generateLegalAgreementText(siteTermsText)}
    </>
  );

  const Footer = () => (
    <footer className="awsscm-captive-portal-footer">
      <Box float='right'>
        <Button variant='primary' onClick={onSubmitContinue}>{strings.continue}</Button>
      </Box>
    </footer>
  )

  return (
    // Captive Portal is outside the scope of an Argo Application, so Polaris root class name is required here
    // in order to properly apply styles.
    <div className="awsui-visual-refresh">
      <AppLayout
        contentHeader={<ContentHeader />}
        content={<Content />}
        footerSelector=".awsscm-captive-portal-footer"
        disableBodyScroll={true}
        disableContentHeaderOverlap={true}
        toolsHide={true}
        navigationHide={true}
      />
      <Footer />
    </div>

  );
}