
export type AuthConfig = {
    region: string;
    clientId: string;
    authorityUrl: string;

    identityProviderUrl: string;
    domain: string;
    scope: string;
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
    jwkEndpoint : string;
    authorizationEndpoint : string;
    sessionServiceEndpoint: string;
    tokenEndpoint?: string;
    userInfoEndpoint? : string;
    endSessionEndpoint: string;

    tokenExpirationTime?: number;
    implicitFlowRefreshEndpoint?: string;
}

export type AuthSession = {
    idToken : string;
    credentials : SessionServiceCredential | null;
}

export type SessionServiceCredential = {
    credentials : {
        accessKeyId : string;
        secretAccessKey : string;
        sessionToken : string;
        expiration : number;
    }
    userDirectoryGroupList : string[];
}

export type AuthProfile = {
    sub : string;
    'cognito:groups' : string[];
    'cognito:preferred_role' : string;
    'custom:user_id' : string;
    'cognito:username' : string;
    given_name : string;
    'custom:groups' : string[];
    'cognito:roles' :string[];
    // identities sample: [{"userId":"duanlian","providerName":"Amazon","providerType":"SAML","issuer":"https://idp-integ.federate.amazon.com","primary":"true","dateCreated":"1596566417640"}],
    identities : {
        userId : string;
        providerName : string;
        providerType : string;
        issuer : string;
        primary : boolean;
        dateCreated: number;
    }
    token_use : string;
    auth_time : number;
    family_name : string;
    email : string;
}

export type AuthUser = {
    id_token : string;
    access_token : string;
    refresh_token : string;
    token_type: string;
    scope: string;
    profile : AuthProfile | null;
    expires_at :number;
}


export enum AuthResponseType {
    Code = 'code',
    IdToken = 'id_token',
}