export default {
    text: `2008 - ${new Date().getFullYear()}, Amazon Web Services, Inc. or its affiliates. All rights reserved.`,
    privacyPolicy: {
        text: 'Privacy Notice',
        link: 'https://aws.amazon.com/privacy/'
    },
    termsOfUse: {
        text: 'Site Terms',
        link: '#'
    }
}
