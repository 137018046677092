import * as React from 'react';
import { FrameworkStage, FrontEndFeatureSet } from "../../../ArgoEnums";
import initialMetricsPublisher, { ArgoMetricsConfig } from "../../../utils/metrics/metrics";
import katalLogger from "../../../utils/logger/logger";
import { ArgoAppContextProvider } from "../../../ArgoContext";
import * as LoggerUtility from "../../../utils/logger/utility";
import { Level } from '@katal/logger';

interface DevAppProps {
  frameworkStage: FrameworkStage;
  frontEndFeatureSet: FrontEndFeatureSet;
  content: React.ReactElement;
  userName: string;
  customGroups?: string[];
}

export function DevApp(props: DevAppProps) {
  const browserName = LoggerUtility.getUserBrowserName();
  const deviceType = LoggerUtility.getUserDeviceType();
  const deviceOS = LoggerUtility.getUserDeviceOS();
  const locale = LoggerUtility.getUserLocale();
  const appName = 'Argo';
  const region = 'us-west-2';
  const cell = 'cell-0;'
  const applicationVisitId = '';
  const argoSessionId = '';

  const devAppMetricsConfig: ArgoMetricsConfig = {
    appName,
    region,
    frameworkStage: props.frameworkStage,
    cell,
    browserName,
    deviceType,
    deviceOS,
    locale,
    applicationVisitId,
    argoSessionId
  };

  const metricPublisher = initialMetricsPublisher(devAppMetricsConfig);
  const context = {
    user: {
      username: props.userName,
      signOut: () => {
        return;
      },
    },
    frameworkStage: props.frameworkStage,
    frontEndFeatureSet: props.frontEndFeatureSet,
    fetchWithWebIdentity: (input: RequestInfo, init?: RequestInit) => {
      const fetchStartTime = new Date().valueOf();
      return fetch(input, init).then((response) => {
        metricPublisher
          .newChildActionPublisherForMethod('FetchTimeForGraphQl')
          .publishCounterMonitor(
            'FetchTimeForGraphQl',
            new Date().valueOf() - fetchStartTime
          );
        return response;
      });
    },
    fetchWithAwsAuth: (input: RequestInfo, init?: RequestInit) => {
      const fetchStartTime = new Date().valueOf();
      return fetch(input, init).then((response) => {
        metricPublisher
          .newChildActionPublisherForMethod('FetchTimeForGraphQl')
          .publishCounterMonitor(
            'FetchTimeForGraphQl',
            new Date().valueOf() - fetchStartTime
          );
        return response;
      });
    },
    custom_groups: props.customGroups || [],
    initialMetricsPublisher: metricPublisher,
    logger: katalLogger(
      Level.DEBUG,
      {
        appName
      },
      true
    ),
    region
  };

  return (
    <ArgoAppContextProvider value={context}>
      {props.content}
    </ArgoAppContextProvider>
  );
}