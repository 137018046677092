import * as KatalMetrics from '@katal/metrics';
import { v4 as uuidv4 } from 'uuid';
import { credentialStorage } from '../../CredentialStorage';
import { BROWSER_SESSION_ID_KEY } from './constants';
/**
 * If a browser-session-id is present, then the user is continuing from an existing session in a new app.
 * If no browser-session-id is present, then we're starting a new session. This should not be confused with a
 * user's authentication session: https://w.amazon.com/bin/view/AWS_SCM/AuthTeam/#HSessionService
 */
const getBrowserSessionId = () => {
  let browserSessionIdValue = sessionStorage.getItem(BROWSER_SESSION_ID_KEY);
  if (!browserSessionIdValue) {
    browserSessionIdValue = uuidv4();
    sessionStorage.setItem(BROWSER_SESSION_ID_KEY, browserSessionIdValue);
  }

  return browserSessionIdValue;
}

export const createUserUsagePublisher = (metricPublisher: KatalMetrics.Publisher, isAuthInitialized: boolean) => {
  if (isAuthInitialized) {
    const userIdMetric = new KatalMetrics.Metric.String('user-id', credentialStorage.getUserUniqueId());
    const sessionIdMetric = new KatalMetrics.Metric.String(BROWSER_SESSION_ID_KEY, getBrowserSessionId());

    return metricPublisher.newChildPublisher({
      relatedMetrics: [userIdMetric, sessionIdMetric],
      methodName: 'user-usage'
    });
  } else {
    throw new Error('User Usage Publisher created before Auth initialization');
  }
}