import { get as _get } from 'lodash';
import { getArgoConfig } from '../../window';
import { ArgoHadesClient } from './ArgoHadesClient';
import { ArgoHadesDevClient } from './ArgoHadesDevClient';

const config = getArgoConfig();

// argoHadesUrl is defined in AWSSCMArgoHadesDeploy package.
// If AWSSCMArgoHadesDeploy is not deployed in this stack, assume that this is a dev stack.
const isHadesConfigured = Boolean(_get(config, 'argo.argoHadesUrl', false));

const argoHadesClient = isHadesConfigured ? new ArgoHadesClient() : new ArgoHadesDevClient();
export default argoHadesClient;