export default {
  continue: 'Continue',
  legalAgreementText: {
    partOne: 'By continuing, you agree to the',
    partTwo: 'and the following:'
  },
  privacyPolicyText: 'Privacy Notice',
  privacyPolicyLink: 'https://aws.amazon.com/privacy/',
  siteUse: 'Terms of agreement'
}
