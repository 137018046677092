import React from 'react';
import { Box, Icon, TextContent } from '@amzn/awsui-components-react-v3';
import strings from './strings';

export const generateLegalAgreementText = (siteTermsText: string) => {
  const PrivacyPolicyLink = () => {
    return (
      <a
        target="_blank"
        href={strings.privacyPolicyLink}
        rel="noopener noreferrer"
      >
        {strings.privacyPolicyText} <Icon variant="link" name="external" />
      </a>
    );
  }

  // siteTermsText comes to us from Hades in the form of a block of HTML, which we need to render.
  const SiteAgreementText = () => (
    <span>
      <TextContent>
        <div dangerouslySetInnerHTML={{ __html: siteTermsText }} />
      </TextContent>
    </span>
  );

  return (
    <div>
      <Box>
        <p>
          {strings.legalAgreementText.partOne}&nbsp;
          <PrivacyPolicyLink />
          {strings.legalAgreementText.partTwo}&nbsp;
        </p>
      </Box>
      <span>
        <SiteAgreementText />
      </span>
    </div>
  )
}