import * as React from 'react';
import initialMetricsPublisher, { ArgoMetricsConfig } from '../../utils/metrics/metrics';
import katalLogger from '../../utils/logger/logger';
import * as KatalMetrics from '@katal/metrics';
import { Level } from '@katal/logger';

interface ErrorBoundaryProps {
  appName: string;
  region: string;
  frameworkStage: string;
  cell: string;
  katalLoggerUrl: string;
  argoHadesUrl: string;
  browserName: string;
  deviceType: string;
  deviceOS: string;
  locale: string;
  applicationVisitId: string;
  argoSessionId: string;
}

interface ErrorBoundaryState {
  actionMetricsPublisher: KatalMetrics.Publisher;
  hasError?: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
  > {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    const errorBoundaryMetricsConfig: ArgoMetricsConfig = {
      ...this.props
    }

    this.state = {
      actionMetricsPublisher: initialMetricsPublisher(errorBoundaryMetricsConfig).newChildActionPublisherForMethod('FatalError')
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: object) {
    const logToConsole = this.props.appName === 'argo-test' ? true : false;
    this.emitErrorMetric();
    this.emitErrorLog(error, info, logToConsole, this.props.katalLoggerUrl)
  }

  emitErrorMetric = () => {
    this.state.actionMetricsPublisher.publishCounterMonitor(
      'FatalError',
      1
    );
  };

  emitErrorLog = (error: Error, info: object, logToConsole: boolean, katalLoggerUrl?: string) => {
    katalLogger(
      Level.WARN,
      {
        appName: this.props.appName,
      },
      logToConsole,
      katalLoggerUrl
    ).error('FatalError', error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ margin: '10px' }}>
          Something went wrong with the Infra Portal. Email aws-scm-ux@amazon.com for assistance.
        </div>
      );
    }

    // This will render the Cognito or Dev app component.
    return <div>{this.props.children}</div>;
  }
}

export default ErrorBoundary;
