import * as React from 'react';
import { Box } from '@amzn/awsui-components-react-v3';
import { getArgoConfig } from '../../window';
import { get as _get } from 'lodash';
import { credentialStorage } from '../../utils/CredentialStorage';
import './navbar.scss';

interface NavigationProps {
  isLandingPage?: boolean;
  navigationMenu: JSX.Element | JSX.IntrinsicElements | null;
}
interface NavigationHeaderProps {
  isApplication: boolean;
}
interface NavigableApplicationsObject {
  navTitle: string;
  navLink: string;
  allowsExternalUsers: boolean;
}

export function Navigation({ isLandingPage = false, navigationMenu }: NavigationProps) {
  const isLandingPageApp = isLandingPage;
  const config = getArgoConfig();
  const navigableApplications = _get(config, 'argo.navigableApplications', []);
  const isExternalUser = credentialStorage.isExternalUser();

  const tvpLink = _get(config, 'argo.tvpLink', '');
  const tvpLinkText = 'Titan Vendor Portal';
  const enableTVPLinkForExternalUser = _get(config, 'argo.enableTVPLinkForExternalUser', false);

  // Only used for LandingPage App
  const applicationLinks: React.ReactElement[] = [];
  navigableApplications.forEach((app: NavigableApplicationsObject) => {
    // External Users should not see links for apps that do not allow External Users
    if (isExternalUser && !app.allowsExternalUsers) {
      return;
    }

    applicationLinks.push(
      <div>
        <li key={applicationLinks.length}>
          <a href={app.navLink}>{app.navTitle}</a>
        </li>
      </div>
    );
  });

  // Add tvpLink only if there is a TVP link present in argo-config
  if (tvpLink) {
    // If 'enableTVPLinkForExternalUser' flag is true then the link should be shown only for external users
    // If 'enableTVPLinkForExternalUser' is false, then link should be shown only for internal users
    if ((enableTVPLinkForExternalUser && isExternalUser) || (!enableTVPLinkForExternalUser && !isExternalUser)) {
      applicationLinks.push(
        <div>
          <li key={applicationLinks.length}>
            <a href={tvpLink}>{tvpLinkText}</a>
          </li>
        </div>
      );
    }
  }

  // Only used for LandingPage App
  const navigationStructure =
    <div className="navigation_content">
      <div className="awsui-side-navigation">
        {applicationLinks}
      </div>
    </div>

  return (
    <Box className='argocore_navigation'>
      <NavigationHeader isApplication={!isLandingPageApp} />
      {isLandingPage ? navigationStructure : navigationMenu}
    </Box>
  );
}

export function NavigationHeader(props: NavigationHeaderProps) {

  return (
    <div className='navigation_header'>
      <div className='navHeader'>
        <a href='/' className='navHomeLink'>
          <h1>Infra Portal</h1>
        </a>
      </div>
      <div className='switchApp'>
        {props.isApplication && <a href='/'>Switch App</a>}
      </div>
    </div>
  );
}
