/**
 * Defines the stage that the Argo Framework is deployed in.
 * All customer owned endpoints will have a FrameworkStage of Prod.
 */
export enum FrameworkStage {
    alpha = 'alpha',
    beta = 'beta',
    prod = 'prod',
    dev = 'dev'
}

/**
 * The Customer defined feature set for the endpoint.
 */
export enum FrontEndFeatureSet {
    Test = 'Test',
    Prerelease = 'Prerelease',
    Release = 'Release'
}
