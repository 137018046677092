// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
export const LightThemes = [
  { value: 'chrome', label: 'Chrome' },
  { value: 'clouds', label: 'Clouds' },
  { value: 'crimson_editor', label: 'Crimson Editor' },
  { value: 'dawn', label: 'Dawn' },
  { value: 'dreamweaver', label: 'Dreamweaver' },
  { value: 'eclipse', label: 'Eclipse' },
  { value: 'github', label: 'GitHub' },
  { value: 'iplastic', label: 'IPlastic' },
  { value: 'solarized_light', label: 'Solarized Light' },
  { value: 'textmate', label: 'TextMate' },
  { value: 'tomorrow', label: 'Tomorrow' },
  { value: 'xcode', label: 'Xcode' },
  { value: 'kuroir', label: 'Kuroir' },
  { value: 'katzenmilch', label: 'KatzenMilch' },
  { value: 'sqlserver', label: 'SQL Server' },
] as const;

export const DarkThemes = [
  { value: 'ambiance', label: 'Ambiance' },
  { value: 'chaos', label: 'Chaos' },
  { value: 'clouds_midnight', label: 'Clouds Midnight' },
  { value: 'dracula', label: 'Dracula' },
  { value: 'cobalt', label: 'Cobalt' },
  { value: 'gruvbox', label: 'Gruvbox' },
  { value: 'gob', label: 'Green on Black' },
  { value: 'idle_fingers', label: 'idle Fingers' },
  { value: 'kr_theme', label: 'krTheme' },
  { value: 'merbivore', label: 'Merbivore' },
  { value: 'merbivore_soft', label: 'Merbivore Soft' },
  { value: 'mono_industrial', label: 'Mono Industrial' },
  { value: 'monokai', label: 'Monokai' },
  { value: 'nord_dark', label: 'Nord Dark' },
  { value: 'pastel_on_dark', label: 'Pastel on dark' },
  { value: 'solarized_dark', label: 'Solarized Dark' },
  { value: 'terminal', label: 'Terminal' },
  { value: 'tomorrow_night', label: 'Tomorrow Night' },
  { value: 'tomorrow_night_blue', label: 'Tomorrow Night Blue' },
  { value: 'tomorrow_night_bright', label: 'Tomorrow Night Bright' },
  { value: 'tomorrow_night_eighties', label: 'Tomorrow Night 80s' },
  { value: 'twilight', label: 'Twilight' },
  { value: 'vibrant_ink', label: 'Vibrant Ink' },
] as const;
